
class CameraMover {

    constructor(camera, moveType, speed, distance) {

        this.focusPoint = { x: 0, y: 0, z: 0 }
        this.camera = camera;
        this.speed = speed;
        this.distance = distance;
        this.progression = Math.PI / 2 / speed;
        this.lastTime = Date.now();
        this.moveTypes = [
            "still",
            "rotate",
            "rotate front",
            "rotate back"
        ];
        this.setMovementType(moveType);

        const camBtnsHolder = createElem("div", "", document.getElementById("canvas-holder"), "cam-btns", []);

        for (const moveType of this.moveTypes) {

            const newBtn = createElem("button", moveType, camBtnsHolder, "", ["pop-out-box"]);

            if (moveType == this._moveType_)
                newBtn.classList.add("selected-rot-type");

            newBtn.addEventListener("click", () => {
                this.setMovementType(moveType);
                for (const btn of newBtn.parentElement.children) btn.classList.remove("selected-rot-type");
                newBtn.classList.add("selected-rot-type");
            });
        }
    }

    setMovementType(moveType) {

        const camera = this.camera;

        if (this.moveTypes.indexOf(moveType) != -1) {
            this._moveType_ = moveType;
            if (moveType == "none") {
                camera.position.y = this.focusPoint.y;
                camera.position.z = this.distance * Math.sin(this.progression * this.speed);
                camera.position.x = this.distance * Math.cos(this.progression * this.speed);
                camera.rotation.y = Math.atan2(camera.position.x, camera.position.z);
                camera.rotation.x = 0;
                camera.rotation.z = 0;
            }
        } else console.log("missing rot type");
    }
    update() {

        const camera = this.camera;

        let time = Date.now();
        let deltaTime = time - this.lastTime;
        this.lastTime = time;
        if (this._moveType_ == "rotate") {
            camera.position.y = this.focusPoint.y;
            camera.position.z = this.distance * Math.sin(this.progression * this.speed);
            camera.position.x = this.distance * Math.cos(this.progression * this.speed);
            camera.rotation.y = Math.atan2(camera.position.x, camera.position.z);
            camera.rotation.x = 0;
            camera.rotation.z = 0;
            this.progression += deltaTime;
        } else if (this._moveType_.includes("rotate")) {
            const rotMultplier = this._moveType_.includes("front") ? 1 : -1;
            camera.position.y = this.focusPoint.y;
            camera.position.z = this.distance * rotMultplier * Math.abs(Math.sin(this.progression * this.speed));
            camera.position.x = this.distance * Math.cos(this.progression * this.speed);
            camera.rotation.y = Math.atan2(camera.position.x, camera.position.z);
            camera.rotation.x = 0;
            camera.rotation.z = 0;
            this.progression += deltaTime;
        }
    }
}